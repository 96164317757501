<template>
  <Wrapper>
    <header>Create Subsidiary</header>
    <InputStyled :required="!$v.name.required">
      <input v-model.trim="$v.name.$model" :placeholder="'Subsidiary'" />
    </InputStyled>
    <p class="errors" v-if="$v.name.$dirty && !$v.name.required">{{ $tc('messages.required') }}</p>
    <p class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</p>
    <ButtonsGroup>
      <ButtonStyled :disabled="!$v.name.required || !$v.name.minLength || isLoading" @click="createSubsidiary">
        {{ $tc('actions.create') }}
      </ButtonStyled>
      <ButtonStyled :disabled="isLoading" color="grey" @click="$root.$emit('closeOverlay')">
        {{ $tc('actions.cancel') }}
      </ButtonStyled>
    </ButtonsGroup>
  </Wrapper>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import ORGANIZATION_QUERY from '#/graphql/organizations/show.gql'
import CREATE_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/create.gql'
import { FlashMessages } from '@common/singletons'

import { Wrapper, InputStyled, ButtonsGroup, ButtonStyled } from './styles'

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    Wrapper,
    InputStyled,
    ButtonsGroup,
    ButtonStyled,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },
  data() {
    return {
      name: '',
      isLoading: false,
    }
  },
  computed: {
    organizationID() {
      return this.args.organizationID
    },
  },
  methods: {
    async createSubsidiary() {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: CREATE_SUBSIDIARY_MUTATION,
          variables: {
            data: {
              organization: {
                connect: {
                  id: this.organizationID,
                },
              },
              name: this.name,
            },
          },
          update: (store, { data }) => {
            const { organization } = store.readQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.organizationID,
                },
              },
            })

            organization.subsidiaries.push(data.createSubsidiary)

            store.writeQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.organizationID,
                },
              },
              data: {
                organization,
              },
            })
          },
        })

        FlashMessages.$emit('success', this.$t('messages.entityCreatedSuccessfully', { entity: this.$tc('subsidiary', 1) }), {
          timeout: 3000,
        })

        this.$root.$emit('closeOverlay')
      } catch (err) {
        FlashMessages.$emit('error', err)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
